import React, { useLayoutEffect, useRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import gsap from "gsap";
import emailjs from "@emailjs/browser";
import {useSelector, useDispatch} from "react-redux";
import {closeModal} from "../state/modalSlice";

const ContactFormModal = () => {
    const isOpen = useSelector((state) => state.modal.isOpen);
    const dispatch = useDispatch();
    const [hasRendered, setHasRendered] = React.useState(false);
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().email("Invalid email").required("Email is required"),
        message: yup.string().required("Message is required"),
    });

    const { register, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const initialModalStyle = useMemo(
        () => ({
            opacity: 0,
            zIndex: -1,
        }),
        []
    );

    const finalModalStyle = useMemo(
        () => ({
            opacity: 1,
            zIndex: 100,
        }),
        []
    );

    const initialScreenStyle = useMemo(
        () => ({
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(28,28,28,0.85)",
            zIndex: -1,
            opacity: 0,
        }),
        []
    );

    const finalScreenStyle = useMemo(
        () => ({
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(28,28,28,0.85)",
            zIndex: 99,
            opacity: 1,
        }),
        []
    );

    const formRef = useRef(null);
    const screenRef = useRef(null);

    useLayoutEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
            return;
        } else {
            var tl = gsap.timeline();
        }

        if (!isOpen) {
            tl.to(formRef.current, {
                ...initialModalStyle,
                duration: 0.25,
                ease: "back",
            })
                .set(formRef.current, {
                    ...initialModalStyle,
                })
                .to(screenRef.current, {
                    ...initialScreenStyle,
                    duration: 0.25,
                    ease: "back",
                })
                .set(screenRef.current, {
                    ...initialScreenStyle,
                });
        } else {
            tl.to(screenRef.current, {
                ...finalScreenStyle,
                duration: 0.25,
                ease: "back",
            })
                .set(screenRef.current, {
                    ...finalScreenStyle,
                })
                .to(formRef.current, {
                    ...finalModalStyle,
                    duration: 0.25,
                    ease: "back",
                })
                .set(formRef.current, {
                    ...finalModalStyle,
                });
        }
    }, [
        finalModalStyle,
        finalScreenStyle,
        hasRendered,
        initialModalStyle,
        initialScreenStyle,
        isOpen,
    ]);

    const handleClose = () => {
        reset({
            name: "",
            email: "",
            message: "",
        });
        dispatch(closeModal());
    };

    const handleFormSubmit = () => {
        const serviceId = "gmail";
        const templateId = "studio42_v2_email_form";
        const userId = "user_E6Sw8jgkSYgDQSn8TxTYv";
        emailjs
            .sendForm(serviceId, templateId, formRef.current, userId)
            .then((result) => {
                console.log(result.text);
                window.alert("Message sent!");
            })
            .catch((error) => {
                console.log(error.text);
            })
            .finally(() => {
                handleClose();
            });
    };

    return (
        <div id="contact-form-modal" ref={screenRef}>
            <form
                id="contact-form"
                ref={formRef}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input id="name" type="text" {...register("name")} />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" {...register("email")} />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        rows="8"
                        cols="36"
                        id="message"
                        type="text"
                        {...register("message")}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "1.5rem",
                        width: "350px",
                        position: "absolute",
                        bottom: "4rem",
                    }}
                >
                    <button className="form-button" onClick={handleClose}>
            Go Back
                    </button>
                    <button className="form-button" type="submit">
            Submit
                    </button>
                </div>
                <small>
                    <a
                        id="pp-link"
                        href="https://privacypolicy.studio42dev.com"
                        target="privacy_policy"
                    >
            Studio42 Web Development privacy policy
                    </a>
                </small>
            </form>
        </div>
    );
};

export default ContactFormModal;
