import React, { useLayoutEffect, useRef, useState } from "react";
import logo from "../assets/logo.svg";
import Navbar from "./Navbar";
import { gsap } from "gsap";
import { useSelector } from "react-redux";

const Header = () => {
    const isAtTop = useSelector((state) => state.scroll.isAtTop);
    const [hasEntered, setHasEntered] = useState(false);
    const logoRef = useRef(null);
    const screenRef = useRef(null);

    useLayoutEffect(() => {
        if (isAtTop || hasEntered) return;
        setHasEntered(true);

        gsap.fromTo(
            screenRef.current,
            {
                opacity: 0.98,
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#282828)",
                zIndex: "49",
                boxShadow:
          "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            },
            {
                top: "0",
                left: "0",
                width: "100%",
                height: "0%",
                backgroundColor: "rgba(28,28,28)",
                zIndex: "49",
                ease: "power3.inOut",
                duration: 0.75,
                opacity: 0,
            }
        );

        gsap.fromTo(
            logoRef.current,
            {
                height: "36rem",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            },
            {
                height: "12rem",
                position: "fixed",
                top: "0",
                left: "0",
                ease: "power3.inOut",
                transform: "translate(0, 0)",
                duration: 0.75,
                margin: "-2rem -1rem",
            }
        );
    }, [hasEntered, isAtTop]);

    const handleImageClick = () => {
        window.scrollTo({
            top: 0.1,
            behavior: "smooth",
        });
    };

    return (
        <header id="App-header">
            <img
                onClick={handleImageClick}
                ref={logoRef}
                src={logo}
                className="App-logo"
                alt="logo"
            />
            <div id="screen" ref={screenRef} />
            <Navbar />
        </header>
    );
};

export default Header;
