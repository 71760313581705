import { createSlice } from "@reduxjs/toolkit";

export const scrollSlice = createSlice({
    name: "scroll",
    initialState: {
        isAtTop: true,
    },
    reducers: {
        setAtTop: (state) => {
            state.isAtTop = true;
        },
        unSetAtTop: (state) => {
            state.isAtTop = false;
        }
    },
});

export const { setAtTop, unSetAtTop } = scrollSlice.actions;

export default scrollSlice.reducer;
