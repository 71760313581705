import React from "react";
import web_devices from "../assets/Web devices-amico.svg";
import fast_loading from "../assets/Fast loading-amico.svg";
import document from "../assets/Online document-rafiki.svg";
import insight from "../assets/User research-bro.svg";
import iteration from "../assets/Product iteration-amico.svg";
import accessibility from "../assets/accessibility.png";

export const panels = [
    {
        data: {
            id: "responsive",
            heading: "Responsive websites",
            text: "Today's users are on devices of all types and sizes from phones to smart TVs. Keeping your website or application working and looking great no matter how or where your users interact with it is an absolute necessity.",
            image: web_devices,
            Element: null,
        },
    },
    {
        data: {
            id: "fast",
            heading: "Fast load times",
            text: "Not all users are on high-speed connections. The size of your website can seriously impact its performance and the user's experience. With a custom-built solution from Studio42 Web Development, your application carries only the code necessary to keep your load times fast and your users on your site.",
            image: fast_loading,
            Element: null,
        },
    },
    {
        data: {
            id: "userFocused",
            heading: "User-focused",
            text: "We focus on the user's experience and intuitive workflows in the apps and websites that we create. Intuitive navigation lets your users focus on the content, not how to get there.",
            image: document,
            Element: null,
        },
    },
    {
        data: {
            id: "insight",
            heading: "Custom Data Solutions",
            text: "Keeping track of clients, appointments, and other data in spreadsheets is tedious, at best. When you need to gain quick access to information, search, or create new records - databases are a much more efficient, flexible, and secure solution.",
            image: insight,
            Element: null,
        },
    },
    {
        data: {
            id: "accessibility",
            heading: "Accessible",
            text: "Accessibility is a key component of any website or application and a requirement for for-profit websites. We build our sites and applications with accessibility in mind from the ground up. We also offer accessibility audits and remediation services for existing sites and applications.",
            image: accessibility,
            Element: null,
        },
    },
    {
        data: {
            id: "tools",
            heading: "The Right Tools For The Job",
            text: null,
            image: iteration,
            Element: () => (
                <>
                    <h3>
            Every job needs the right tools, and here are just a few in our
            toolbox:
                    </h3>
                    <ul>
                        <li>Relational and non-relational databases</li>
                        <li>Progressive Web applications</li>
                        <li>Mobile applications</li>
                        <li>APIs</li>
                        <li>Email Integration</li>
                        <li>Hosting</li>
                        <li>Website Administration</li>
                    </ul>
                </>
            ),
        },
    },
];
