import React from "react";
import { panels } from "../app_data";
import loadable from "@loadable/component";

const Home = () => {
    const LoadablePanel = loadable(() => import("./Panel"));

    return (
        <div className="App-content">
            {panels.map((panel, index) => (
                <LoadablePanel key={index} data={{ ...panel.data, index }} />
            ))}
        </div>
    );
};

export default Home;
