import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../state/modalSlice";
import gsap from "gsap";
import { useSelector } from "react-redux";
import ChatOnceEmbed from "./ChatOnceEmbed";

const Navbar = () => {
    const dispatch = useDispatch();
    const isAtTop = useSelector((state) => state.scroll.isAtTop);
    const [hasEntered, setHasEntered] = useState(false);
    const navRef = useRef(null);

    useEffect(() => {
        if (isAtTop || hasEntered) return;
        setHasEntered(true);

        gsap.fromTo(
            navRef.current,
            {
                top: "-50vh",
            },
            {
                top: "1.5rem",
            }
        );
    }, [isAtTop, hasEntered]);

    const handleClick = () => {
        console.log("dispatching openModal()");
        dispatch(openModal());
    };

    return (
        <nav className="nav-wrapper">
            <ul className="nav-list" ref={navRef}>
                <ChatOnceEmbed />
                <li id="e-mail" className="nav-link" onClick={handleClick}>
                    Email
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
