import React, {useEffect, useRef } from "react";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Header from "./components/Header";
import gsap from "gsap";
import ContactFormModal from "./components/ContactFormModal";
import {useDispatch} from "react-redux";
import {setAtTop, unSetAtTop} from "./state/appSlice";
import ReactGA from "react-ga4";

ReactGA.initialize("G-Z54XX5GXMS");

function App() {
    const dispatch = useDispatch();

    const helperRef = useRef(null);
    useEffect(() => {
        const helperAnim = gsap.to(helperRef.current, {
            duration: 1,
            delay: 2,
            opacity: 1,
        });
        helperAnim.play();
        const onScroll = () => {
            if (window.scrollY === 0) {
                dispatch(setAtTop());
            } else {
                helperAnim.reverse();
                dispatch(unSetAtTop());
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <div id="App">
            <Header/>
            <Home/>
            <Footer/>
            <div ref={helperRef} className="helper-text">
                <small>scroll to view content...</small>
            </div>
            <ContactFormModal />
        </div>
    );
}

export default App;
