import React, { useEffect } from "react";

const ChatOnceEmbed = () => {
    useEffect(() => {
        // Create the script element
        const script = document.createElement("script");
        script.id = "co-index";
        script.src = "https://cdn.oncehub.com/co/widget.js";
        script.setAttribute("data-co-params", "website_id=WEB-89272ADC30&bot_type=2");
        script.defer = true;

        // Append the script to the body or head
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts

    return (
        <li className="nav-link" data-co-bot="BOT-2C6BC8F2C9">
            Start chat
        </li>
    );
};

export default ChatOnceEmbed;
