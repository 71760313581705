import React from "react";

const Footer = () => {
    return (
        <div id="footer">
            <span className="cta">
        Schedule a free 15-minute consultation to speak with one of our
        developers
            </span>
            <a href="https://go.oncehub.com/Greg5" target="new">
                <span className="cta-button">Get Started</span>
            </a>
        </div>
    );
};

export default Footer;
